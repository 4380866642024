<template>
  <div>
    <el-form style="width: 100%">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请输入学生姓名">
            <el-input placeholder="请输入学生姓名" v-model="searchData.name" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-button type="primary" @click="onSearch"> 搜索 </el-button>
            <el-button
              v-permission="['financeAddFees']"
              style="margin-left: 20px"
              type="primary"
              @click="onChangeTheTime"
            >
              添加
            </el-button>
            <el-button type="primary" @click="batchDistribution">
              批量删除
            </el-button>
            <el-button type="primary" @click="templateDownload">
              模板下载
            </el-button>
            <el-button type="primary" @click="excelImport">导入</el-button>
            <el-button
              :loading="loading"
              type="success"
              @click="onPayTheFeesexport"
            >
              导出
            </el-button>
            <el-button
              v-permission="['financeAddFees']"
              type="primary"
              @click="contrastResults"
              >对比结果</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格 -->
    <el-table
      :data="oldStudent"
      border
      style="width: 100%"
      stripe
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column fixed type="selection" width="55" />
      <el-table-column prop="name" label="姓名" width="80" />
      <el-table-column prop="sex" label="性别" width="50" />
      <el-table-column
        prop="id_card"
        show-overflow-tooltip
        label="身份证号码"
        width="180"
      />
      <el-table-column prop="collegeName" label="院系" show-overflow-tooltip />
      <el-table-column
        prop="domain"
        label="专业"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column prop="length" label="层次" show-overflow-tooltip />
      <el-table-column prop="grade" label="年级" width="80" />
      <el-table-column prop="year" label="入学年份" width="80" />
      <el-table-column prop="className" label="班级" width="180" />
      <el-table-column prop="teacher" label="老师姓名" />
      <el-table-column prop="remark" label="备注" />
      <el-table-column label="操作" align="center" width="180" fixed="right">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="onEditBtn(row)"
            >修改</el-button
          >
          <el-popconfirm
            title="是否删除?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="deleteBtn(row.id)"
          >
            <template #reference>
              <el-button type="danger" size="small"> 删除 </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchData.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchData.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <student-edit
      v-model="oldStudentShow"
      :studentID="studentID"
      :onListOldStudent="onListOldStudent"
    ></student-edit>
    <student-add
      v-model="timeVisible"
      :studentID="studentID"
      :onListOldStudent="onListOldStudent"
    ></student-add>
    <contrast
      v-model="contrastVisible"
      :studentID="studentID"
      :onListOldStudent="onListOldStudent"
    ></contrast>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import {
  stulibListAPI,
  stulibDelAPI,
  stuliblotDelAPI,
  reportStulibListAPI
} from '@/api/studentsdb'
import { listOfHierarchicalListingAPI } from '@/api/specialized'
import { ElMessage } from 'element-plus'
import studentEdit from './components/student-edit.vue'
import studentAdd from './components/student-add.vue'
import contrast from './components/contrast.vue'

// 搜索表单
const searchData = ref({
  page: 1, // 当前页
  total: 0, // 总数
  limit: 10, //  每页几条数据
  name: '' //  学生姓名
})
// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()
// 搜索
const onSearch = () => {
  onListOldStudent()
}

// 模板下载
const templateDownload = () => {
  window.location.href = 'https://lnjsxy.college.yslts.com/excel/librarys.xls'
}
const loading = ref(false)
const onPayTheFeesexport = async () => {
  loading.value = true
  const data = await reportStulibListAPI(searchData.value)
  window.location.href = `https://lnjsxy.college.yslts.com${data}`
  loading.value = false
}
// 批量删除
const ids = ref([])
// 多选框选中数据
const handleSelectionChange = (selection) => {
  // 获取所有选中项的gh(工号)属性的值
  ids.value = selection.map((item) => item.id).join()
}
const batchDistribution = async () => {
  const tempObj = {
    page: searchData.value.page,
    limit: searchData.value.limit,
    ids: ids.value
  }
  console.log('tempObj', tempObj)
  await stuliblotDelAPI(tempObj)
  onListOldStudent()
  ElMessage.success('批量删除成功')
}

/**
 *  表格 S
 */
const oldStudent = ref([])
const onListOldStudent = async () => {
  const { data, total } = await stulibListAPI(searchData.value)
  oldStudent.value = data
  searchData.value.total = total
}
onListOldStudent()

// 修改
const studentID = ref({})
const onEditBtn = ({ id }) => {
  studentID.value = id
  oldStudentShow.value = true
}
/** 添加 S */
const timeVisible = ref(false)
const item = ref({})
const onChangeTheTime = (row) => {
  timeVisible.value = true
  item.value = row
}
const contrastVisible = ref(false)
const cont = ref({})
const contrastResults = (row) => {
  contrastVisible.value = true
  cont.value = row
}

// 删除
const deleteBtn = async (id) => {
  await stulibDelAPI({ id })
  ElMessage.success('删除成功 。')
  onListOldStudent()
}
// Excel 导入
const router = useRouter()
const excelImport = () => {
  router.push('/studentsdb/import')
}

/**
 *  表格 E
 */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  searchData.value.limit = val
  onListOldStudent()
}

// 页码值
const handleCurrentChange = (val) => {
  searchData.value.page = val
  onListOldStudent()
}

/**
 *  分页 E
 */

/** 修改 S */
const oldStudentShow = ref(false)

/** 修改 E */
</script>

<style lang="scss" scoped></style>
